import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'
import cleanUpQuotes from '../utils/cleanup-quotes'

export default ({data: {page}}) => {
  const {title, content} = page

  return (
    <Layout className="has-header">
      <SEO title={title} />
      <Header />
      <div className="with-bg h-100 minimum-full-height">
        <div className="container">
          <article className="py-4">
            <div className="decorator my-5"></div>
            <header>
              <h1>
                {title}
              </h1>
            </header>
            <section dangerouslySetInnerHTML={{__html: cleanUpQuotes(content)}}></section>
            <div className="decorator my-5 mr-auto"></div>
          </article>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
